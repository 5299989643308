.footerWrapper {
  min-height: 260px;
}

.footerContentContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  flex-wrap: wrap;
}
.optionsContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  gap: 60px;
  margin-top: 54px;
}
.socialContainer {
  display: flex;
  gap: 16px;
  margin-top: 36px;
}
.socialName {
  font-size: 14px;
  font-weight: 600;
  line-height: 24.5px;
}
.socialImg {
  width: 41px;
}
.separator {
  width: 959px;
  max-width: 100%;
  height: 1px;
  background-color: #c2c2c2;
  margin-top: 38px;
}
.authentication {
  margin-top: 20px;
  font-size: 14px;
  font-weight: 700;
  line-height: 24.5px;
}

@media (max-width: 61.9375em) {
  .optionsContainer {
    column-gap: 50px;
    row-gap: 20px;
  }
  .authentication {
    font-size: 10px;
    margin-bottom: 10px;
  }
}
