.chartSortBy__wrapper {
  position: absolute;
  top: 52px;
  right: 188px;
}

.chartSortBy {
  position: relative;
  padding: 7px 12px 7px 18px;
  min-width: 155px;
  min-height: 40px;
}

.chartSortBy:hover .chartSortBy__menu {
  display: block;
}

.chartSortBy__main {
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
}

.chartSortBy__main--text {
  line-height: 25px;
  text-align: left;
}

.chartSortBy__main--icon {
  width: 24px;
  height: 24px;
}

.chartSortBy__menu {
  display: none;
  position: absolute;
  top: calc(100% + 1px);
  right: 0;
  width: 200px;
  background-color: #fff;
  border: 1px solid #ffffff1a;
  border: 1px solid rgba(194, 194, 194, 0.5);
}

.chartSortBy__menu::before {
  content: "";
  position: absolute;
  top: -2px;
  left: 0;
  right: 0;
  height: 3px;
}

.chartSortBy__menu--title {
  font-size: 16px;
  line-height: 24px;
}

.chartSortBy__menu--options {
  padding: 8px;
  font-family: var(--fourth-font);
}

/* Mobile and Tablet */
@media (max-width: 61.9375em) {
  .chartSortBy__wrapper {
    top: 120px;
  }
}

/* Mobile <= 380 */
@media (max-width: 23.75em) {
  .chartSortBy__wrapper {
    right: 160px;
  }

  .chartSortBy {
    min-width: 100px;
  }

  .chartSortBy__menu {
    width: 160px;
    right: unset;
    left: 0;
  }
}
