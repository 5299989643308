.dropdownDate {
  position: absolute;
  top: 0;
  right: 0;
  padding: 8px 10px 8px 15px;
  min-width: 140px;
  min-height: 40px;
  border-radius: 4px;
  cursor: pointer;
  border-radius: 4px;
  border: 1px solid #c2c2c2;

  margin-top: 32px;
  margin-right: 37px;
  font-family: var(--main-font);
}

.date__show {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 6px;
}

.activeDate {
  font-size: 14px;
  font-weight: 600;
  line-height: 24.5px;
}

.date__arrow {
  width: 24px;
  height: 24px;
}

.date__menu {
  position: absolute;
  top: 100%;
  right: 0;
  padding: 8px 0;
  border-radius: 4px;

  background-color: var(--secondary-color);
  color: var(--main-color);
  cursor: default;
}

.date__option {
  padding: 2px 14px;
  min-width: 100px;
}

.date__option:hover {
  cursor: pointer;
  opacity: 0.8;
}

/* Mobile and Tablet */
@media (max-width: 61.9375em) {
  .date {
    font-size: 13px;
  }
}
