.sectionContainer {
  margin-top: 34px;
}
.sectionName {
  line-height: 24.5px;
  font-size: 24px;
  font-weight: 800;
  color: var(--main-color);
  margin-bottom: 24px;
}
.boardContainer {
  display: flex;
  flex-direction: column;
  border: 1px solid #c2c2c2;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
  background-color: var(--secondary-color);
  padding: 15px 52px 36px 52px;
}
.seeAllContainer {
  cursor: pointer;
  display: flex;
  align-items: center;
  align-self: flex-end;
  font-size: 16px;
  font-weight: 600;
  gap: 4px;
}
.nextArrow {
  width: 24px;
}
.news {
  margin-top: 12px;
  display: flex;
  gap: 88px;
  overflow: auto;
}

/* Mobile: width <= 767px */
@media (max-width: 47.9375em) {
  .boardContainer {
    padding-left: 15px;
    padding-right: 15px;
  }
  .sectionName {
    font-size: 20px;
    font-weight: 700;
    line-height: 24.5px;
    margin-bottom: 16px;
  }

  .news {
    gap: 34px;
  }
  .seeAllContainer {
    font-size: 14px;
    font-weight: 700;
    line-height: 22px;
  }
  .nextArrow {
    width: 20px;
  }
}
