.navbarItem {
  padding: 8px 32px;
  border-radius: 4px;
  color: var(--secondary-color);
  background-color: var(--main-color);

  font-weight: 600;
  line-height: 25px;
  cursor: pointer;
}

.navbarItem.navbarItem--active {
  background-color: var(--secondary-color);
  color: var(--main-color);
}

/* Mobile and Tablet */
@media (max-width: 61.9375em) {
  .navbarItem {
    text-align: center;
    padding: 6px 50px;
  }
}
