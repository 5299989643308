.quantityLine {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 62px;
  font-family: var(--secondary-font);

  border: 2px solid;
  border-image-source: linear-gradient(
    180deg,
    rgba(0, 0, 0, 0) 50%,
    rgba(0, 0, 0, 0.08) 100%
  );
  border-radius: 4px;
  box-shadow: 0px 0px 1px 0px #0000001f;
}

.quantityLine__character {
  position: relative;
  height: 100%;
  display: flex;
  align-items: center;

  padding: 0 11px;
  font-size: 20px;
  line-height: 30px;
  font-weight: 700;
}

.quantityLine__character ~ .quantityLine__character::before {
  content: "";
  position: absolute;
  left: -1px;
  top: -2px;
  width: 2px;
  height: calc(100% + 4px);
  background-color: #0000001f;
}
