.chartTabs {
  display: flex;
  border-radius: 12px 12px 0px 0px;
  background-color: var(--background-color);
  overflow: auto;
}

.chartTabs__item {
  position: relative;
  padding: 20px;
  cursor: pointer;

  display: flex;
  align-items: center;
  justify-content: center;
  gap: 5px;
  min-width: 88px;
  flex-shrink: 0;

  background-color: var(--background-card);
  border-radius: 12px 12px 0px 0px;
  border: 1px solid var(--background-card);
  border-bottom: none;
}

.chartTabs__item.chartTabs__item--active {
  background-color: var(--secondary-color);
  border-color: var(--border-color);
}

.chartTabs__item--icon {
  width: 24px;
  height: 24px;
}

.chartTabs__item--text {
  font-weight: 500;
  line-height: 21px;
}

/* Mobile and Tablet */
@media (max-width: 61.9375em) {
  .chartTabs__item {
    padding: 20px 12px;
  }

  .chartTabs__item--text {
    font-size: 13px;
    line-height: 20px;
  }
}
