.chartDatePicker__wrapper {
  position: absolute;
  top: 52px;
  right: 20px;
}

.chartDatePicker {
  position: relative;
}

.chartDatePicker__input {
  width: 154px;
  padding: 7px 8px 7px 17px;
  font-family: var(--main-font);
  font-size: 14px;
  font-weight: 400;
  line-height: 25px;
  color: var(--main-color);
  text-align: left;
}

.chartDatePicker__calendar {
  right: 28px;
}

.chartDatePicker__icon {
  position: absolute;
  top: 7px;
  right: 8px;
  width: 24px;
  height: 25px;
}

/* Mobile and Tablet */
@media (max-width: 61.9375em) {
  .chartDatePicker__wrapper {
    top: 120px;
  }
}

/* Mobile <= 380 */
@media (max-width: 23.75em) {
  .chartDatePicker__input {
    width: 130px;
  }
}

.react-datepicker__month-read-view, .react-datepicker__year-read-view{
  visibility: visible !important;
}

.react-datepicker__year-dropdown--scrollable, .react-datepicker__month-dropdown--scrollable, .react-datepicker__month-year-dropdown--scrollable{
  height: 250px !important;
}
.react-datepicker__month-read-view--selected-month {
  font-size: 14px !important;
}

.react-datepicker__year-read-view--selected-year {
  font-size: 14px !important;
}
