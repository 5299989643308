.container {
  max-width: 100%;
  border: 2px solid #c2c2c2;
  background-color: var(--secondary-color);
}
.contentContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 26px 170px 23px 170px;
  gap: 15px;
}
.logoContainer {
  display: flex;
  gap: 52px;
  margin: 0 auto;
}
.welcom__logo {
  height: 60px;
}
.hookContainer {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-wrap: nowrap;
  line-height: 28px;
  color: var(--main-color);
}
.hook {
  text-align: center;
  width: 100%;
  font-size: 16px;
  font-weight: 500;
  line-height: 28px;
}
.projectName {
  font-family: var(--secondary-font);
  font-weight: 800;
}

/* Mobile and Tablet */
@media (max-width: 61.9375em) {
  .contentContainer {
    padding-left: 14px;
    padding-right: 14px;
    gap: 25px;
  }

  .hookContainer {
    text-wrap: wrap;
    text-align: center;
  }

  .welcom__logo {
    height: 50px;
  }
}
