.chartSortByItem {
  padding: 8px;
  display: flex;
  align-items: center;
  gap: 8px;
  cursor: pointer;
}

.chartSortByItem:hover {
  opacity: 0.8;
}

.chartSortByItem__checkbox {
  width: 24px;
  height: 24px;
  background: url("../../../../../../public/images/commons/checkbox.png")
    no-repeat center;
  background-size: contain !important;
}

.chartSortByItem--checked .chartSortByItem__checkbox {
  background: url("../../../../../../public/images/commons/checkbox-checked.png")
    no-repeat center;
}

.chartSortByItem__text {
  flex: 1;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: left;
  text-transform: capitalize;
}
