.signUpBox {
  padding: 36px 53px 53px 48px;
  color: var(--main-color);
  border-radius: 4px;
  border: 1px solid #c2c2c2;
  background-color: var(--secondary-color);
}
.stayUpToDateContainer {
  display: flex;
  flex-direction: column;
}

.stayUpToDateText {
  font-size: 24px;
  font-weight: 700;
  line-height: 28px;
}
.stayUpToDateHook {
  font-size: 16px;
  font-weight: 500;
  line-height: 28px;
  margin-top: 4px;
  margin-bottom: 27px;
}
.signUpContent {
  display: flex;
  justify-content: space-between;
  gap: 9px;
}
.inputBox {
  border-radius: 4px;
  border: 1px solid #c2c2c2;
  background: #f3f3f3;
  padding: 6px 20px;
  width: 279px;
  background-color: var(--background-card);
  color: var(--main-color);
  font-size: 16px;
  font-weight: 500;
  line-height: 28px;
}
.inputBox::placeholder {
  color: var(--main-color);
  font-weight: 600;
}
.signUpButton {
  flex: 1;
  padding: 4px 0;
  min-width: 120px;
  border-radius: 4px;
  background-color: var(--main-color);
  color: var(--secondary-color);
  font-size: 14px;
  font-weight: 600;
  line-height: 28px;
}

/* Mobile and Tablet */
@media (max-width: 61.9375em) {
  .signUpBox {
    padding: 20px 20px 30px 20px;
  }

  .signUpContent {
    flex-direction: column;
    align-items: center;
    gap: 10px;
  }

  .inputBox {
    width: 400px;
    max-width: 100%;
    padding: 6px 16px;
  }

  .signUpButton {
    padding: 6px 0;
  }
}

/* Mobile: width <= 767px */
@media (max-width: 47.9375em) {
  .signUpBox {
    padding: 36px 15px 42px 15px;
  }
  .inputBox {
    width: 100%;
  }
  .stayUpToDateHook {
    margin-top: 12px !important;
    font-size: 14px;
    font-weight: 500;
    line-height: 28px;
  }
  .signUpButton {
    width: 100%;
  }
}
