.navBarWrapper {
  /* position: fixed;
  top: var(--header-intro-height);
  left: 0;
  right: 0; */
}

.navBarWrapper__list {
  display: flex;
  justify-content: center;
  gap: 28px;
  padding: 13px 0;
}

/* Mobile and Tablet */
@media (max-width: 61.9375em) {
  .navBarWrapper {
    position: fixed;
    top: var(--header-mobile-height);
    left: 0;
    right: 0;
    z-index: 999;
  }

  .navBarWrapper__list {
    flex-direction: column;
    gap: 0;
  }
}
