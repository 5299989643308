.activityContainer {
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
  overflow: auto;
}
.titleContainer {
  display: flex;
  overflow: auto;
}
.informationContainer {
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 6px;
  padding: 18px 20px;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  border-left: 1px solid var(--background-card);
  border-top: 1px solid var(--background-card);
  border-right: 1px solid var(--background-card);
  min-width: 176px;
  background-color: var(--background-card);

  border: 1px solid var(--background-card);
  border-bottom: none;
}
.informationContainer.informationContainer--active {
  background-color: var(--secondary-color);
  border-color: var(--border-color);
}
.icon {
  width: 25px;
}
.inforName {
  font-weight: 600;
}
.whiteBg {
  border-right: 1px solid var(--background-card);
  background-color: var(--secondary-color);
}

/* Mobile and Tablet */
@media (max-width: 61.9375em) {
  .informationContainer {
    text-align: center;
    min-width: fit-content !important;
    padding: 20px !important;
  }
}
