/* reset css */
* {
  margin: 0;
  padding: 0;
  -webkit-box-sizing: inherit;
  box-sizing: inherit;
}

html {
  font-size: 14px;
  line-height: 1.5;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

html,
body {
  font-family: var(--main-font) !important;
  font-style: normal;
  font-weight: 400;
  height: auto !important;
  background-color: var(--background-color);
  color: var(--main-color);
}

a {
  text-decoration: none;
  cursor: pointer;
}

a:hover {
  text-decoration: none;
}

img {
  max-width: 100%;
}

button {
  cursor: pointer;
}

button {
  border: none;
  outline: none;
}

input {
  outline: none;
}

ul {
  list-style-type: none;
  margin: 0;

  margin-block-start: 0;
  margin-block-end: 0;
  margin-inline-start: 0;
  margin-inline-end: 0;
  padding-inline-start: 0;
}

h1,
h2,
h3,
h4,
h5,
h6,
p,
a {
  font-size: 14px;
  font-weight: 400;
  line-height: 1.5;
}

/* only project */
.bgGreenColorWhite {
  background-color: var(--main-color);
  color: var(--secondary-color);
}

.bgWhieColorGreen {
  color: var(--main-color);
  background-color: var(--secondary-color);
}

.bgDark {
  background-color: var(--background-color-dark);
}

.flexRow {
  display: flex;
  align-items: center;
  justify-self: center;
}

.inputBgBorder {
  border: 1px solid var(--border-color);
  border-radius: 4px;
  background-color: var(--background-card);
}

.bgWhiteFullAbsolute {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: #fff;
}

/* PC: width >= 992px */
@media (min-width: 62em) {
  .hideOnDesktop {
    display: none !important;
  }
}

/* Mobile and Tablet */
@media (max-width: 61.9375em) {
  .hideOnMobileTablet {
    display: none !important;
  }
}

/* override tippy */
.tippy-box {
  background-color: transparent !important;
}
