.bodyContent {
  padding: 35px 0 35px;
}

/* Mobile and Tablet */
@media (max-width: 61.9375em) {
  .bodyContent {
    padding-top: calc(
      var(--header-mobile-height) + var(--navbar-mobile-height) + 35px
    );
  }
}
