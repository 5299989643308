.slider-container {
  display: flex;
  align-items: center;
}

.leftArrow {
  position: absolute;
  top: 2%;
  left: 1.5%;
}

.rightArrow {
  position: absolute;
  top: 2%;
  right: 1.5%;
}

.rc-slider {
  appearance: none;
  -webkit-appearance: none;
  width: 200px;
  height: 10px;
  background: rgba(69, 72, 51, 0.3);
  outline: none;
  opacity: 0.7;
  transition: opacity 0.2s;
  margin-right: 10px;
  height: 25px !important;
  width: 98% !important;
  position: absolute !important;
  left: 1%;
  top: 2% !important;
  right: 1%;
}

.rc-slider-rail {
  display: none;
}

.rc-slider-track {
  display: none;
}

.rc-slider-step {
  display: none;
}
.rc-slider-handle {
  width: 113px !important;
  height: 13px !important;
  background: #454833 !important;
  cursor: pointer;
  margin: auto !important;
  border: 0px !important;
  border-radius: 0px !important;
}

.rc-slider-handle:active {
  border: 0 !important;
  box-shadow: 0 !important;
}

.rc-slider-handle-dragging {
  box-shadow: 0 !important;
  border-color: 0 !important;
}

.cs-tooltip-inner {
  background-color: white;
  color: black;
  padding: 8px 22px;
  display: flex;
  text-align: center;
  justify-content: center;
  border-radius: 4px;
  box-shadow: 0px 4px 11px 0px rgba(0, 0, 0, 0.1);

  font-family: var(--main-font);
  font-size: 14px;
  font-weight: 400;
  line-height: 25px;
}

.tippy-box[data-theme~="dateScrollToollip"][data-placement^="bottom"]
  > .tippy-arrow {
  top: 4px;
}

.tippy-box[data-theme~="dateScrollToollip"][data-placement^="bottom"]
  > .tippy-arrow::before {
  border-bottom-color: #fff;
}

.tippy-box[data-theme~="dateScrollToollip"][data-placement^="bottom"]
  > .tippy-arrow {
  box-shadow: 0px 4px 11px 1px rgba(0, 0, 0, 0.25);
}




/* Mobile: width <= 767px */
@media (max-width: 47.9375em) {
  .rc-slider-handle {
    width: 25px !important;
  }
}
