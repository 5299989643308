.languageDropdown {
  position: relative;
  padding: 8px 10px 8px 15px;
  min-width: 120px;
  min-height: 40px;
  border-radius: 4px;
  cursor: pointer;
}

.languageDropdown__show {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 11px;
}

.activeLanguage {
  font-weight: 700;
  line-height: 25px;
  text-transform: uppercase;
}

.languageDropdown__arrow {
  width: 24px;
  height: 24px;
}

.languageDropdown__menu {
  position: absolute;
  top: 100%;
  right: 0;
  padding: 8px 0;
  border-radius: 4px;

  background-color: var(--secondary-color);
  color: var(--main-color);
  cursor: default;
}

.languageDropdown__option {
  padding: 2px 14px;
  min-width: 100px;
}

.languageDropdown__option:hover {
  cursor: pointer;
  opacity: 0.8;
}

/* Mobile and Tablet */
@media (max-width: 61.9375em) {
  .languageDropdown {
    font-size: 13px;
  }
}
