.headerWrapper {
  /* position: fixed;
  top: 0;
  left: 0;
  right: 0; */
  min-height: var(--header-intro-height);
  padding: 26px;
  background-color: var(--background-color);
}

.headerWrapper__content {
  display: flex;
  justify-content: space-between;
  gap: 200px;
}

.headerWrapper__left {
  flex: 1;
  display: flex;
  justify-content: left;
  align-items: center;
  gap: 10px;
}

.headerWrapper__logo {
  width: 116px;
  height: 116px;
}

.headerWrapper__info {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 8px;
  text-align: left;
}

.headerWrapper__name {
  font-family: var(--secondary-font);
  font-size: 22px;
  font-weight: 600;
  line-height: 25px;
  text-transform: uppercase;
}

.headerWrapper__slogan {
  font-size: 14px;
  font-weight: 500;
  line-height: 25px;
}

/* Mobile and Tablet */
@media (max-width: 61.9375em) {
  .headerWrapper {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 999;
    min-height: unset;
    padding: 0;
    box-shadow: 0px 4px 11px 0px #0000001a;
  }

  .headerWrapper__content {
    height: var(--header-mobile-height);
    gap: 20px;
    align-items: center;
  }

  .headerWrapper__logo {
    width: 90px;
    height: 90px;
    position: relative;
    left: calc(-1 * var(--body-padding-side));
  }

  .headerWrapper__menuOverlay {
    position: fixed;
    top: var(--header-mobile-height);
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 998;
  }

  .headerWrapper__left {
    gap: 0;
  }

  .headerWrapper__right {
    position: fixed;
    top: var(--header-mobile-height);
    left: 0;
    width: 100%;
    height: var(--navbar-mobile-height);
    z-index: 999;

    flex-direction: row-reverse;
    justify-content: space-between;

    padding: 0 var(--body-padding-side);
    background-color: var(--main-color);
    color: var(--secondary-color);
  }

  .headerWrapper__menu--mobile {
    padding: 6px 8px;
    display: flex;
    cursor: pointer;

    border-radius: 4px;
    background: #fff;
  }

  .headerWrapper__menu--mobile__icon {
    width: 24px;
    height: 24px;
  }

  .headerWrapper__menu--mobile__text {
    display: flex;
    align-items: center;
    padding: 0 12px;
  }

  .headerWrapper__info {
    gap: 6px;
  }

  .headerWrapper__name {
    font-size: 14px;
  }

  .headerWrapper__slogan {
    font-size: 12px;
  }
}

/* Mobile <= 360 */
@media (max-width: 22.5em) {
  .headerWrapper__info {
    gap: 0;
  }

  .headerWrapper__name,
  .headerWrapper__slogan {
    line-height: 18px;
  }
}
