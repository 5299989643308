.violation__boardContainer {
  display: flex;
  flex-direction: column;
  border-left: 1px solid #c2c2c2;
  border-right: 1px solid #c2c2c2;
  border-bottom: 1px solid #c2c2c2;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
  background-color: var(--secondary-color);
  padding: 15px 52px 36px 52px;
}
/* Mobile: width <= 767px */
@media (max-width: 47.9375em) {
  .violation__boardContainer {
    padding-left: 15px;
    padding-right: 15px;
  }
}
