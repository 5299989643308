.orgChart {
  margin-top: 35px;
  border-radius: 12px 12px 3px 3px;

  display: flex;
  flex-direction: column;
}

.orgChart.orgChart--zoomOut {
  margin-top: 0;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 9999;
}

.orgChart .svg-chart-container {
  height: 640px !important;
}

.orgChart.orgChart--zoomOut .orgChart__chart {
  flex: 1;
  min-height: unset;
}

.orgChart.orgChart--zoomOut .svg-chart-container {
  width: 100vw;
  height: calc(100vh - 120px) !important;
}

.orgChart__chart {
  position: relative;
  min-height: 600px;
  border: 1px solid var(--border-color);
  border-top: none;
  background-color: var(--secondary-color);
}

.orgChart__footer {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 16px;
  padding: 5px 20px;

  border-radius: 0 0 3px 3px;
  border: 1px solid var(--border-color);
  border-top: none;

  font-family: var(--third-font);
  font-size: 12px;
  font-weight: 500;
  line-height: 12px;
}

.orgChart__footer--icon {
  width: 40px;
}

.chartZoomIcon {
  position: absolute;
  bottom: 25px;
  right: 21px;
  cursor: pointer;
}

.chartZoomIcon__image {
  width: 40px;
  height: 40px;
}

.center-group>.nodes-wrapper>.node{
  outline: none;
}

/* Mobile and Tablet */
@media (max-width: 61.9375em) {
  .orgChart__footer {
    padding: 9px 22px 9px 10px;
    line-height: 18px;
  }

  .orgChart.orgChart--zoomOut .svg-chart-container {
    height: calc(100vh - 140px) !important;
  }
}
