:root {
  /* fonts */
  --main-font: Poppins;
  --secondary-font: Spectral;
  --third-font: Inter;
  --fourth-font: Jost;

  /* sizes */
  --body-width: 1250px;
  --body-min-width: 920px;
  --body-padding-side: 16px;
  --header-intro-height: 167px;
  --header-navbar-height: 66px;
  --document-card-width: 301px;
  --header-mobile-height: 120px;
  --navbar-mobile-height: 50px;
  --margin-top-part: 35px;

  /* colors */
  --main-color: #454833;
  --secondary-color: #fff;
  --background-color: #f1f1f1;
  --background-card: #4548334d; /* #454833 0.3 */
  --background-color-dark: #bbbcb6;
  --border-color: #c2c2c2;
}
