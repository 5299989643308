.publicationContainer {
  border: 1px solid #c2c2c2;
  border-radius: 4px;
  background-color: var(--secondary-color);
  padding: 15px 38px 17px 14px;
  margin-top: 13px;
}

.publicationContainer.bolder {
  border: 1px solid var(--main-color);
}

.insideContent {
  display: flex;
  gap: 24px;
}

.insideContent:hover > .imgContainer > .pubplicationImg {
  filter: none;
}

.imgContainer {
  max-width: 218px;
  max-height: 174px;
}

.pubplicationImg {
  width: 217px;
  height: 174px;
  object-fit: cover;
  border-radius: 4px;
  filter: brightness(1) saturate(0%) invert(21%) sepia(38%) saturate(105%)
    hue-rotate(29deg) brightness(85%) contrast(87%);
}

.bodyContainer {
  margin: 7px 0;
  width: 100%;
  flex-shrink: 20;
  color: var(--main-color);
}

.nameContainer {
  font-size: 15px;
  font-weight: 600;
  margin-bottom: 10px;
}

.descriptionContainer {
  max-width: 555px;
  margin-bottom: 10px;
  font-weight: 300;
}

.authorContainer {
  font-family: var(--secondary-font);
  font-size: 15px;
  font-weight: 700;
  margin-bottom: 10px;
}

.footerContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.btnContainer {
  display: flex;
  gap: 8px;
}

.publicationContainer__btn {
  padding: 8px 14px;
  font-weight: 600;
  line-height: 22px;
  color: var(--main-color);
}

.date {
  font-size: 15px;
  font-family: var(--secondary-font);
}

/* Mobile: width <= 767px */
@media (max-width: 47.9375em) {
  .publicationContainer {
    padding: 14px 14px 12px 14px;
  }

  .insideContent {
    flex-direction: column;
    align-items: center;
    gap: 24px;
  }

  .footerContainer {
    gap: 10px 20px;
    flex-wrap: wrap;
  }

  .btnContainer {
    flex-wrap: wrap;
  }

  .imgContainer {
    max-width: unset;
    max-height: unset;
    width: 100%;
  }

  .pubplicationImg {
    width: 100%;
    height: auto;
  }

  .bodyContainer {
    margin: 0 0 8px 0;
  }

  .nameContainer {
    font-size: 14px;
  }

  .descriptionContainer {
    margin-bottom: 9px;
    font-size: 13px;
    line-height: 22px;
  }

  .authorContainer {
    margin-bottom: 9px;
    font-size: 14px;
  }

  .publicationContainer__btn {
    padding: 6px 14px;
  }
  .date {
    font-size: 14px !important;
  }
}
