.graphContainer {
  position: relative;
  width: 100%;
  height: 383px;
  background-color: var(--background-card);
  border-radius: 4px;
  border: 1px solid rgba(69, 72, 51, 0.5);
  overflow: hidden;
}

.graphContainer--zoomOut {
  margin-top: 0;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 9999;
  background-color: white;
}

.graphContainer--zoomOut.wrapper {
  width: 100%;
  background-color: rgba(69, 72, 51, 0.3);
}

.graphContainer--zoomOut.graphContainer {
  flex: 1;
  min-height: unset;
  height: 100vh;
}

.graphContainer--zoomOut svg {
  width: 100vw;
  height: 100vh;
}
.lineGraph__sectionName {
  line-height: 24.5px;
  font-size: 24px;
  font-weight: 800;
  color: var(--main-color);
  margin-bottom: 16px;
}
.linesChartZoomIcon {
  position: absolute;
  bottom: 6px;
  right: 21px;
  cursor: pointer;
}
