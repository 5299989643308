.newsElementContainer {
  max-width: 158px;
  display: flex;
  flex-direction: column;
  align-items: center;
  cursor: pointer;
}

.image {
  max-width: 100%;
  filter: brightness(1) saturate(0%) invert(21%) sepia(38%) saturate(105%)
    hue-rotate(29deg) brightness(85%) contrast(87%);
}

.newsElementContainer:hover > .image {
  filter: none;
}

.name {
  font-size: 16px;
  font-weight: 600;
  text-decoration: underline;
  margin-top: 16px;
}

.name.noTextWrap {
  text-wrap: nowrap;
}

.description {
  text-align: center;
  margin-top: 8px;
}
@media (max-width: 47.9375em) {
  .newsElementContainer {
    min-width: 158px !important;
  }
  .name {
    font-size: 14px;
    font-weight: 700;
    line-height: 22px;
  }
  .name.noTextWrap {
    text-wrap: wrap !important;
    text-align: center;
  }
  .description {
    font-size: 13px;
    font-style: normal;
    line-height: 22px;
  }
}
