.documentCards {
  display: flex;
  gap: 15px;
  margin-top: 34px;
  overflow: auto;
}

/* Mobile and Tablet */
@media (max-width: 61.9375em) {
  .documentCards {
    margin-top: var(--margin-top-part);
    flex-wrap: wrap;
  }
}
