.x-asis {
  width: 100%;
  font-size: 10px;
  font-weight: 500;
  letter-spacing: -0.3px;
  color: #7d7d7d;
}
.x-asis path {
  display: none;
}
.x-asis g line {
  display: none;
}
.x-axis-grid {
  width: 100%;
  color: #fff;
}

.y-asis {
  font-size: 10px;
  font-weight: 500;
  letter-spacing: -0.3px;
  color: #7d7d7d;
}
.y-asis path {
  display: none;
}

.y-asis g line {
  display: none;
}
.y-axis-grid {
  width: 100%;
  color: #fff;
}
